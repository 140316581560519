<template>
  <v-col class="d-flex flex-column fill-height">
    <v-container class="d-flex flex-column justify-start align-center">
      <h2 class="mt-5 mb-10">Monitor</h2>
    </v-container>
    <v-container class="d-flex flex-column">
      <SubmitButton text="Leaderboard" @click="leaderboardClickHandler" />
    </v-container>
  </v-col>
</template>

<script>
import SubmitButton from '@/components/ui/buttons/SubmitButton'
import routeNames from '@/router/routeNames'

export default {
  name: 'MonitorHome',
  components: { SubmitButton },
  methods: {
    leaderboardClickHandler () {
      this.$router.push(routeNames.monitorLeaderboard)
    }
  }
}
</script>

<style scoped>

</style>
